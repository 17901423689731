import Quill from 'quill';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
// Can't find any types for the ImageResize module
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ImageResize from 'quill-image-resize';
import CustomColor from '@/components/quill/spell-check-fix';
import LoadingImage from '@/components/quill/loading-image';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
Quill.register(CustomColor, true);
Quill.register({ 'formats/imageBlot': LoadingImage });
